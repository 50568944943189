<template>
  <div>Template Filter Option Panel</div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'TemplateFilterOptionPanel',
  mixins: [imageUrlMixin],
  props: {
    resetOption: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      selectedDestination: null,
      selectedDealCategory: null,
      selectedMonth: null,
      fromPrice: null,
      toPrice: null,
    };
  },
  computed: {
    ...mapGetters({
      optionsDestination: 'GET_OPTIONS_DEST_APP_DEAL',
      optionsMonth: 'GET_OPTIONS_MONTH_APP_DEAL',
      optionsCategory: 'GET_OPTIONS_CATE_APP_DEAL',
      rangePriceDeal: 'GET_RANGE_PRICE',
    }),
    showInitAllOption() {
      return this.showInitDestination || this.showInitMonth || this.showInitCategory;
    },
    showInitDestination() {
      return this.selectedDestination !== '' && this.selectedDestination !== null;
    },
    showInitMonth() {
      return this.selectedMonth !== '' && this.selectedMonth !== null;
    },
    showInitCategory() {
      return this.selectedDealCategory !== '' && this.selectedDealCategory !== null;
    },
  },
  watch: {
    open() {
      this.expand = this.open;
    },
    resetOption() {
      if (this.resetOption) {
        this.initializeAllFilterOption();
      }
    },
    selectedDestination() {
      this.updatedFilterOption();
    },
    selectedDealCategory() {
      this.updatedFilterOption();
    },
    selectedMonth() {
      this.updatedFilterOption();
    },
    fromPrice() {
      this.updatedFilterOption();
    },
    toPrice() {
      this.updatedFilterOption();
    },
  },
  methods: {
    gotoHome() {
      this.$router.push('/');
    },
    showMenu() {
      this.$emit('emitMessage', {
        kind: 'toggleMenu',
        data: null,
      });
    },
    initializeAllFilterOption() {
      this.selectedDestination = null;
      this.selectedMonth = null;
      this.selectedDealCategory = null;
    },
    initializeDestOption() {
      this.selectedDestination = null;
    },
    initializeMonthOption() {
      this.selectedMonth = null;
    },
    initializeCategoryOption() {
      this.selectedDealCategory = null;
    },
    openOptionPanel() {
      this.expand = true;
    },
    closeOptionPanel() {
      this.expand = false;
    },
    updatedFilterOption() {
      this.$emit('emitMessage',
        {
          kind: 'updatedFilterOption',
          data: {
            destination: this.selectedDestination,
            category: this.selectedDealCategory,
            month: this.selectedMonth,
            fromPrice: this.fromPrice,
            toPrice: this.toPrice,
          },
        });
    },
    filterDeals() {
      this.updatedFilterOption();
      this.expand = false;
    },
  },
};
</script>

<style scoped></style>
